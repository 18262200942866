import { graphql } from 'gatsby';
import React from 'react';

import Header from '../components/header';
import Layout from '../components/layout';
import LessonDetails from '../components/lessonDetails';
import Seo from '../components/seo';

export default ({ data }) => (
  <Layout>
    <Seo
      title={data.datoCmsLesson.title}
      description={data.datoCmsLesson.description}
    />
    <Header showCloseBtnWithUrl={`/lessons#${data.datoCmsLesson.url}`} />
    <LessonDetails {...data.datoCmsLesson} />
  </Layout>
)

export const query = graphql`
  query($id: String!) {
    datoCmsLesson(id: { eq: $id }) {
      number
      title
      url
      description
      image {
        fluid(maxWidth: 1000) {
          ...GatsbyDatoCmsFluid
        }
        url
      }
      lessonType
      video {
        providerUid
      }
    }
  }
`
