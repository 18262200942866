import { navigate } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import styles from '../sass/components/lessonDetails.module.scss';
import SvgArrowRight from '../svg/arrowRight';
import Container from './container';
import Link from './link';

export default class LessonDetails extends React.Component {
  state = { videoIsPlaying: false }

  componentDidMount() {
    this.loadYoutubeVideo()
  }

  loadYoutubeVideo() {
    const checkIfYoutubeApiIsLoaded = document.querySelector(
      'script[src="https://www.youtube.com/iframe_api"]'
    )
      ? true
      : false

    const loadScript = () => {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }

    const initVideoAfterReady = () =>
      (window.onYouTubeIframeAPIReady = () => this.createVideo())

    if (!checkIfYoutubeApiIsLoaded) {
      loadScript()
      initVideoAfterReady()
    } else {
      this.createVideo()
    }
  }

  createVideo() {
    return new window.YT.Player('js-video', {
      height: '',
      width: '',
      videoId: this.props.video.providerUid,
      events: {
        onReady: event => this.onPlayerReady(event),
        onStateChange: event => this.onPlayerStateChange(event),
      },
      playerVars: { rel: 0, modestbranding: 1 },
    })
  }

  onPlayerReady(event) {
    event.target.playVideo()
    this.setState({ videoIsPlaying: true })
  }

  onPlayerStateChange({ data }) {
    if (data === 0) navigate(`/${this.props.url}/quiz`) //ended
  }

  render() {
    const { number, title, description, image, url, lessonType } = this.props

    return (
      <section className={styles.lessonDetails}>
        <div className={styles.lessonDetails__in}>
          <figure className={styles.lessonDetails__video}>
            <div className={styles.lessonDetails__video__in} id="js-video" />

            {!this.state.videoIsPlaying && (
              <Img
                fluid={{
                  ...image.fluid,
                  src: `${image.url}?w=1024`,
                  srcSet: `${image.url}?w=375 375w, ${image.url}?w=768 768w, ${
                    image.url
                  }?w=1024 1024w, ${image.url}?w=1440 1440w, ${
                    image.url
                  }?w=1920 1920w, ${image.url}?w=2520 2520w, ${
                    image.url
                  }?w=3840 3840w`,
                  sizes: `100vw`,
                  srcSetWebp: `${image.url}?w=375&fm=webp 375w, ${
                    image.url
                  }?w=768&fm=webp 768w, ${image.url}?w=1024&fm=webp 1024w, ${
                    image.url
                  }?w=1440&fm=webp 1440w, ${image.url}?w=1920&fm=webp 1920w, ${
                    image.url
                  }?w=2560&fm=webp 2560w, ${image.url}?w=3840&fm=webp 3840w`,
                  srcWebp: `${image.url}?w=1024&fm=webp`,
                }}
                className={styles.lessonDetails__video__image}
                Tag="figure"
                critical={true}
                style={{ position: '' }}
                imgStyle={{ objectFit: '' }}
                alt={`${number} - ${title}`}
              />
            )}
          </figure>

          <Container>
            <div className={styles.lessonDetails__inner}>
              <h1 className={`${styles.lessonDetails__number} ${lessonType && '_color-kozel-2'}`}>{number}</h1>
              <h2 className={`${styles.lessonDetails__title} ${lessonType && 'font-kozel _color-kozel'}`}>{title}</h2>
              <div
                className={`${styles.lessonDetails__text}  ${lessonType && 'font-apex _color-kozel'}`}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />

              <Link
                className={styles.lessonDetails__button}
                to={`/${url}/quiz`}
              >
                Jump to question
                <SvgArrowRight />
              </Link>
            </div>
          </Container>
        </div>
      </section>
    )
  }
}
